import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CustomerPayment } from '../../models';

export const featureAdapter: EntityAdapter<CustomerPayment> = createEntityAdapter<
  CustomerPayment
>({
  selectId: (model) => model.id,
});

export interface State extends EntityState<CustomerPayment> {
  pagination: IPagination;
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = featureAdapter.getInitialState({
  pagination: null,
  isLoading: false,
  error: null,
});
