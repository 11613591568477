import { createAction, props } from '@ngrx/store';

import { IPagination } from '../../core/models/api/pagination/pagination.model';
import { CustomerPayment } from '../../models';

export const loadRequest = createAction(
  '[Customer Payments] Load Request',
  props<{ customerId: number; properties: number[]; page: number }>(),
);

export const loadSuccess = createAction(
  '[Customer Payments] Load Success',
  props<{ customerPayments: CustomerPayment[]; pagination: IPagination }>(),
);

export const loadFailure = createAction(
  '[Customer Payments] Load Failure',
  props<{ error: any }>(),
);

export const deleteRequest = createAction(
  '[Customer Payments] Delete Request',
  props<{ reservationId: number; paymentId: number }>(),
);

export const deleteSuccess = createAction(
  '[Customer Payments] Delete Success',
  props<{ paymentId: number }>(),
);

export const deleteFailure = createAction(
  '[Customer Payments] Delete Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Customer Payments] Reset State');
