import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { ErrorHandlerService } from '../../core/services/error-handler.service';
import { CustomerPayment } from '../../models';
import { CustomerPaymentsService } from '../../services';
import { NotificationService } from '../../ui/services/notification.service';

import * as fromActions from './actions';

@Injectable()
export class CustomerPaymentsStoreEffects {
  constructor(
    private dataService: CustomerPaymentsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
    private notifications: NotificationService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadRequest),
      switchMap(({ customerId, properties, page }) =>
        this.dataService.load(customerId, properties, page).pipe(
          map((response: IResponseSuccess<CustomerPayment[]>) =>
            fromActions.loadSuccess({
              customerPayments: response.data,
              pagination: response.meta.pagination,
            }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadFailure(error));
          }),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.deleteRequest),
      switchMap(({ reservationId, paymentId }) =>
        this.dataService.delete(reservationId, paymentId).pipe(
          map(() => {
            this.notifications.deleteSuccess('payment');
            return fromActions.deleteSuccess({
              paymentId,
            });
          }),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.deleteFailure(error));
          }),
        ),
      ),
    ),
  );
}
